@import "~styles/globals.scss";
.activeBtn {
  padding: 12px 24px;
  height: min-content;
  color: $colorTertiaryWhite;
  border: 3px solid $colorPrimaryHighlight;
  border-radius: 90px;
  cursor: pointer;
  background-color: $colorPrimaryHighlight;
  margin: 0 $margin * 1.33 0 $margin * 0.5;
  transition: background-color 0.25s, border 0.25s;

  &:hover {
    background-color: $orange;
    border: 3px solid $orange;
  }

  @include TextHeadingSmall16;
}

.btnOptions {
  display: flex;
  justify-content: space-evenly;
  margin-top: $margin;
}

.description {
  @include TextBodySmall18;
  text-align: center;
}
