@import "~styles/globals.scss";
.wrapper {
  margin-bottom: $margin * 1.25;
}

.container {
  background-color: rgba(114, 116, 145, 0.1);
  border-radius: $cardRadius * 0.5;
  width: max-content;
  max-width: 100%;
  height: auto;
  padding: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
}

.stretch {
  width: 100%;
}

.clearBg {
  background-color: transparent;
  padding: 0;

  & > div > span:first-of-type > input {
    margin-left: 0;
  }
}

.inputText {
  @include TextBodySmall16;
  margin: 0;
  margin-left: $margin * 0.5;
}

.labelText {
  @include TextHeadingSmall18;
  max-width: 65%;

  @include mobile {
    max-width: 100%;
    width: 100%;
  }
}

.fields {
  display: flex;
  align-items: center;

  @include mobile {
    margin-top: $margin * 0.75;
  }
}

.onlyFields {
  display: flex;
  align-items: center;
  width: 100%;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }

  .mappedField {
    @include mobile {
      &:not(:first-child) {
        margin-left: 0;
        margin-top: $margin * 0.5;
      }
    }
  }
}

.mappedField {
  display: flex;
  align-items: center;

  @include mobile {
    &:not(:first-child) {
      margin-left: $margin * 1.5;
    }
  }
}

.radio {
  appearance: none;
  position: relative;
  width: 36px;
  height: 36px;
  border: 2px solid $inputBorder;
  border-radius: 60px;
  background-color: $colorTertiaryWhite;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.11563);
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    background-color: transparent;
    width: 20px;
    height: 20px;
    top: 6px;
    left: 6px;
    border-radius: $cardRadius;
    transition: background-color 0.5s ease;
  }

  &:checked::before {
    background-color: $colorPrimaryHighlight;
  }

  &:first-of-type {
    margin-left: $margin * 0.75;

    @include mobile {
      margin-left: 0;
    }
  }

  &:not(:first-of-type) {
    margin-left: $margin * 1.5;
  }
}

.errorMargin {
  margin-bottom: $margin * 1.25;
}
