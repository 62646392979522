@import "~styles/globals.scss";
.handleClickArea {
  position: absolute;
  width: 32px;
  height: 32px;
  transform: translate(-50%, -50%);
  background: none;
  cursor: pointer;
  z-index: 5;
}

.handleKnob {
  position: absolute;
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%);
  border: 5px solid $orange;
  border-radius: 50%;
  background-color: white;
  z-index: 2;

  &.disabled {
    background-color: #4d545e;
  }

  &.dark {
    background-color: #1d1f22;
  }
}
