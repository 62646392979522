@import "~styles/globals.scss";
.container {
  width: 694px;
  color: $blue;
  margin-top: 55px;

  @include mobile {
    width: 100vw;
    padding: 0 14px 14px;
    margin-top: $margin;
  }
}

.redactorField {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include TextHeadingLarge36;
    color: $blue;
    margin-bottom: $margin;
  }

  p, li {
    @include TextBodySmall18;
    margin-bottom: $margin;
  }

  li {
    letter-spacing: 0.0141em;
  }

  ul {
    @include mobile {
      padding-left: 20px;
    }
  }
}

.headingXL {
  @include TextHeadingLarge36;
  color: $blue;
  margin-bottom: $margin;
}

.headingL {
  @include TextHeadingLarge30;
  margin-bottom: $margin;
}

.headingM {
  @include TextHeadingMedium24;
  margin: 0 0 $margin;
}

.headingS {
  @include TextHeadingMedium21;
  margin-bottom: $margin * 1.25;

  @include mobile {
    margin: $margin * 0.625 0;
  }
}

.headingXS {
  @include TextHeadingSmall18;
  color: $headingColor;
  margin-bottom: $margin * 1.25;
}

.highlightText {
  color: $colorPrimaryHighlight;
}

.mediumText {
  @include TextBodySmall18;
  margin-bottom: $margin;
}

.list {
  @include mobile {
    padding-left: 20px;
  }
}

.listItem {
  @include TextBodySmall18;
  letter-spacing: 0.0141em;

  &:not(:last-child) {
    margin-bottom: $margin;
  }
}

.mobileHelpText {
  font-family: $font;
  font-weight: 200;
  font-style: italic;
  font-size: 16px;
  line-height: 22px;
  margin: 0;
}

.descriptions {
  @include TextBodySmall16;
}

.greyBoxMain {
  background-color: rgba(114, 116, 145, 0.1);
  border-radius: $cardRadius;
  width: 100%;
  height: auto;
  padding: 36px;

  @include mobile {
    padding: 24px;
  }
}

.greyBoxAlt {
  background-color: rgba(114, 116, 145, 0.1);
  border-radius: $cardRadius * 0.5;
  width: 100%;
  height: auto;
  padding: 18px;
  display: flex;
  align-items: center;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: $inputBorder;
  margin: $margin 0 $margin * 0.833 0;
  border-radius: 2px;
}

.thinDivider,
.mediumDivider,
.thickDivider {
  width: 100%;
  margin: $margin 0 $margin * 0.833 0;
}

.thinDivider {
  border-top: 1px solid $inputBorder;
  margin: $margin * 0.5 0;
}

.mediumDivider {
  border: 1px solid $headingColor;
}

.thickDivider {
  border: 2px solid $headingColor;
  margin: $margin * 2 0 $margin * 1.833;
}

.nextStep {
  @include TextHeadingSmall18;
  padding: 18px 32px;
  background-color: $colorPrimaryHighlight;
  color: $colorTertiaryWhite;
  border-radius: 90px;
  border: none;
  cursor: pointer;
  transition: transform 0.25s;
  width: max-content;
  margin: $margin 0 $margin * 2;

  &:active {
    transform: scale(0.95);
  }

  @include mobile {
    margin: $margin 0;
    width: 100%;
  }
}

.inputSection {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;

  & > div {
    margin-bottom: $margin * 1.25;
  }

  @include mobile {
    justify-content: center;

    & > div,
    & > div > div {
      width: 100%;
    }
  }
}

.hollowBtn,
.activeBtn {
  padding: 12px 24px;
  height: min-content;
  background-color: transparent;
  color: $colorTertiaryWhite;
  border: 3px solid $colorPrimaryHighlight;
  border-radius: 90px;
  cursor: pointer;

  @include TextHeadingSmall16;
}

.hollowBtn {
  transition: background-color 0.25s, border 0.25s;
  color: $orange;

  &:hover {
    background-color: $orange;
    border: 3px solid $orange;
    color: $colorTertiaryWhite;
  }
}

.activeBtn {
  background-color: $colorPrimaryHighlight;
  transition: background-color 0.25s, border 0.25s;

  &:hover {
    background-color: $orange;
    border: 3px solid $orange;
  }
}

.fadeIn {
  animation: fadein 1s ease 0s 1 normal forwards;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  width: 172px;

  span,
  svg {
    width: 64px;
    height: 64px;
    size: 64px;
    stroke: $colorPrimaryHighlight;
  }
}

.toggleDropdown {
  @include mobile {
    display: flex;
    position: relative;
    align-items: center;
    padding-right: 15%;
  }
}

.dropdownSVG {
  position: absolute;
  right: 0;
  transform: rotate(180deg);
  transition: transform 0.5s;
}

.dropdownSvgToggled {
  transform: rotate(0);
}

.hide {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: all 1s ease;
}

.show {
  position: relative;
  visibility: visible;
  opacity: 1;
}

.internalError {
  background-color: $colorLightError;
  padding: 16px 24px;
  border-radius: 8px;
  border: 2px solid $colorError;

  @include TextBodySmall18();
}

.link {
  color: $purple;
  text-decoration: underline;
}
