@import "~styles/globals.scss";
.prereq {
  display: flex;
  position: relative;

  @include mobile {
    width: 100%;
    max-width: 100%;
  }
}

.highlightText {
  color: $colorPrimaryHighlight;
}

.checkbox {
  appearance: none;
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  background-color: $colorTertiaryWhite;
  border: 2px solid $inputBorder;
  outline: none;
  border-radius: 8px;
  margin-right: $margin;
  position: relative;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.11563);

  @include mobile {
    margin: 0 0 0 $margin * 0.5;
  }
}

.checked::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: $colorPrimaryHighlight;
  border-radius: 4px;
  top: 6px;
  left: 6px;
}

.prereqDetails {
  max-width: 558px;
  display: flex;
  flex-direction: column;

  @include mobile {
    max-width: initial;
    width: 100%;
  }
}

.titleWrapper {
  @include mobile {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: $margin * 0.5;
  }
}

.prereqTitle {
  @include TextHeadingMedium24;
  margin-bottom: $margin * 0.5;

  @include mobile {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
}

.prereqText {
  p {
    @include TextBodySmall16;

    strong {
      color: $colorPrimaryHighlight;
    }
  }
}

.uploadBtn {
  @include TextHeadingSmall18;
  padding: 18px 32px;
  background-color: $colorPrimaryHighlight;
  color: $colorTertiaryWhite;
  border-radius: 90px;
  border: none;
  cursor: pointer;
  transition: transform 0.25s;
  width: max-content;

  &:active {
    transform: scale(0.95);
  }

  @include mobile {
    width: 100%;
  }
}

.fileInput {
  display: none;
}

.textButton {
  @include TextBodySmall16;
  margin-bottom: $margin * 0.5;
  width: max-content;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}
