@import "~styles/globals.scss";
$addrPadding: 18px;

.wrapper {
  width: 100%;
}

.container {
  width: 100%;
  height: 77px;
  padding: 12px $addrPadding;
  background-color: $colorTertiaryWhite;
  display: flex;
  flex-direction: column;
  border: 2px solid $inputBorder;
  border-radius: $cardRadius * 0.33;

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  textarea {
    padding: 0;
    border: none;
  }

  input[type='date'] {
    text-transform: uppercase;

    &::placeholder {
      color: $inputBorder;
    }
  }
}

.label {
  @include TextHeadingSmall12;
}

.addressInput {
  @include TextBodyMedium20;
  height: 28px;
  border: none;
  outline: none;

  &::placeholder {
    @include TextBodyMedium20;
    color: $inputBorder;
  }
}

.inputContainer {
  input[type="text"] {
    height: 32px;
  }
}

.dropDown {
  position: relative;
  width: calc(36px + 100%);
  left: - $addrPadding;
  top: $addrPadding - 4;
  background-color: $colorTertiaryWhite;
  z-index: 1;
  border-radius: 0 0 $cardRadius * 0.33 $cardRadius * 0.33;
  border: 2px solid $inputBorder;
  border-top: none;
  overflow: hidden;
}

.dropDownItemActive {
  background-color: $orange;
  transition: background-color 0.25s;
  cursor: pointer;
}

.dropDownItem, .dropDownItemActive {
  padding: $margin * 0.5 $addrPadding;
}
