@import "~styles/globals.scss";
.customDropdownWrapper {
  position: relative;

  .customDropdown {
    display: none;
    position: absolute;
    width: max-content;
    padding: $margin;
    z-index: 2;
    border-radius: $cardRadius;
    background-color: $mainBg;
    top: calc(18px + 18px);
    left: -24px;
    flex-direction: column;

    a {
      color: $colorPrimaryBlueBrand !important; // stylelint-disable-line

      &:hover {
        color: $orange !important; // stylelint-disable-line
      }
    }

    a:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}
