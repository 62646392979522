@import "~styles/globals.scss";
.errorBox {
  margin: $margin * 0.25 0 0;
  color: $red;
  height: auto;
  display: flex;
  align-items: center;
  width: 100%;
}

.errorInput {
  border-color: $red;
  border: 2px solid $red !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.268);
}

.errorInputAlt {
  border: 2px solid $red;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.268);
}

.errorInputGlow {
  background-color: rgba(255, 0, 0, 0.275);
  border-radius: $boxRadius;
}

.errorMsg {
  @include TextHeadingSmall12;
  margin: 0;
}
