@import "~styles/globals.scss";
.highlightText {
  color: $colorPrimaryHighlight;
}

.text {
  @include TextBodySmall16;

  a {
    color: $headingColor;
  }
}

.hide {
  display: none;
}

.uploadBtn {
  @include TextHeadingSmall18;
  padding: 12px 24px;
  border: 3px solid $colorPrimaryHighlight;
  color: $colorPrimaryHighlight;
  background-color: $mainBg;
  border-radius: 90px;
  cursor: pointer;
  transition: transform 0.25s;
  width: max-content;

  &:active {
    transform: scale(0.95);
  }

  @include mobile {
    width: fit-content;
    text-align: center;
  }
}

.fileInput {
  display: none;
}

.textButton {
  @include TextBodySmall16;
  margin-bottom: $margin * 0.5;
  width: max-content;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}
