@import "~styles/globals.scss";
.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: $headerSkinnyHeight;
  background-color: $blue;
  color: $colorTertiaryWhite;
  z-index: 5;

  .headerLeft,
  .headerRight {
    display: flex;
    align-items: center;
  }

  .logo {
    margin: 0 $margin * 5.67 0 $margin;
    width: 95px;
    height: 72px;

    @include mobile {
      width: auto;
      height: 100%;

      img {
        width: 60px;
        height: auto;
      }
    }
  }

  .title {
    @include TextHeadingLarge30;
    margin: 0;

    @include mobile {
      display: none;
    }
  }

  .activeBtn {
    margin: 0 $margin * 1.33 0 $margin * 0.67;

    @include mobile {
      margin: 0 $margin * 0.5 0 $margin * 0.5;
    }
  }

  .headerLeft {
    @include mobile {
      width: 70px;
    }
  }

  @include mobile {
    height: $headerMobileHeight;
  }
}

.hollowBtn,
.activeBtn {
  padding: 12px 24px;
  height: min-content;
  background-color: transparent;
  color: $colorTertiaryWhite;
  border: 3px solid $colorPrimaryHighlight;
  border-radius: 90px;
  cursor: pointer;

  @include TextHeadingSmall16;
}

.hollowBtn {
  transition: background-color 0.25s, border 0.25s;

  &:hover {
    background-color: $orange;
    border: 3px solid $orange;
  }
}

.orangeText {
  color: $orange;

  &:hover {
    color: $colorTertiaryWhite;
  }
}

.activeBtn {
  background-color: $colorPrimaryHighlight;
  transition: background-color 0.25s, border 0.25s;

  &:hover {
    background-color: $orange;
    border: 3px solid $orange;
  }
}

.btnOptions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: $margin;

  button {
    width: 100%;
  }
}

.tick {
  display: flex;
  width: 80px;
  height: 80px;
  margin: $margin auto;
}

.success {
  @include TextBodySmall18;
  text-align: center;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.removeMargin {
  margin: 0;
}

.info {
  @include TextBodySmall18;
  margin-bottom: $margin * 1.25;
  text-align: center;
}
