@import "~styles/globals.scss";
.wrapper {
  display: flex;
  align-items: center;
}

.container {
  width: 208px;
  min-height: 49px;
  background-color: $colorTertiaryWhite;
  display: flex;
  flex-direction: column;
  border: 2px solid $inputBorder;
  border-radius: $cardRadius * 0.33;
  overflow: hidden;

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  textarea {
    padding: 0;
    border: none;
    height: 45px;
  }

  input[type='date'] {
    color: $inputBorder;
    text-transform: uppercase;
  }
}

.innerContainer {
  display: flex;
}

.priceTag {
  @include TextHeadingSmall18;
  display: flex;
  align-items: center;
  margin: 0 $margin * 0.5;
  color: $inputBorder;
}

.stretch {
  width: 100%;
}

.label {
  @include TextHeadingSmall12;
}

.input {
  @include TextBodySmall18;
  border: none;
  outline: none;

  &::placeholder {
    @include TextBodySmall18;
    color: $inputBorder;
  }
}

.errorBox {
  margin: 0 $margin * 0.5 0 0;
  color: $red;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorMsg {
  @include TextBodySmall18;
  margin: 0;
}
