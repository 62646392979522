@import "~styles/globals.scss";
.wrapper {
  display: flex;
  flex-direction: column;
}

.container {
  width: 330px;
  height: 77px;
  padding: 12px 18px;
  background-color: $colorTertiaryWhite;
  display: flex;
  flex-direction: column;
  border: 2px solid $inputBorder;
  border-radius: $cardRadius * 0.33;
}

.container, .innerContainer {
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  textarea {
    padding: 0;
    border: none;

    &::placeholder {
      color: $inputBorder;
    }
  }

  input[type='date'] {
    text-transform: uppercase;
    background-color: transparent;

    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      appearance: none;
    }

    &::placeholder {
      color: $inputBorder;
    }
  }

  input[value=''] {
    color: $inputBorder;
  }
}

.stretch {
  width: 100%;
}

.label {
  @include TextHeadingSmall12;
}

.innerContainer {
  display: flex;
  height: 28px;
  max-height: 28px;
}

.priceTag {
  @include TextHeadingSmall18;
  display: flex;
  align-items: center;
  margin: 0 12px 0 0;
  height: 28px;
  color: $inputBorder;
}

.input {
  @include TextBodyMedium20;
  height: 28px;
  max-height: 28px;
  width: 100%;
  border: none;
  outline: none;

  &::placeholder {
    @include TextBodyMedium20;
    color: $inputBorder;
  }
}
