@import "~styles/globals.scss";
.asset {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.assetLabel {
  @include TextBodySmall18;
  margin: 0;
}

.summary {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > p {
    margin: 0;

    &:last-child {
      text-align: right;
    }
  }
}

.financeSection {
  position: relative;
}

.spacerForPensionType {
  height: 49px;
  position: relative;
  margin-bottom: $margin;
  display: flex;
  align-items: center;
}

.pensionType {
  position: absolute;
  right: calc(208px + 12px);
  margin: 0;
  height: 49px;
  display: flex;
  align-items: center;

  @include mobile {
    right: 0;

    & > div > div {
      flex-direction: row;
      align-items: center;
    }

    & > div > div span {
      margin-top: $margin * 0.5;
      margin-bottom: $margin * 0.5;

      :not(:first-child) {
        margin-right: $margin * 0.5;
      }
    }
  }
}
