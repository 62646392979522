@import "~styles/globals.scss";
.modalOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: $mainBg;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadein 0.5 linear 0s 1 forwards;
}

.container {
  width: 100%;
  height: max-content;
  max-width: 500px;
  background-color: $mainBg;
  border-radius: $cardRadius;
  padding: $margin;
}

.title {
  @include TextHeadingMedium24;
  margin-bottom: $margin * 1.5;
  text-align: center;
  color: $colorPrimaryBlueBrand;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
