@import "~styles/globals.scss";
.summary {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > p {
    margin: 0;
  }
}

.container {
  display: flex;
  justify-content: space-between;

  // For gift name input
  > div:first-child {
    width: 315px;
  }

  // For date & value of gift as they are smaller inputs
  > div:not(:first-child) {
    width: 155px;
  }

  @include mobile {
    flex-direction: column;

    // Adds top margin for mobile inputs
    > div:not(:first-child) {
      margin-top: $margin * 0.5;
    }

    // To make inputs 100% width for mobile
    > div:first-child, > div:not(:first-child), > div > div {
      width: 100%;
    }
  }
}

.smallBtn {
  width: 100%;
}

.addBtn {
  margin: $margin 0 0;
}

.removeBtn {
  margin: $margin 0 0 $margin;
}
