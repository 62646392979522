@import "~styles/globals.scss";
.container {
  width: 275px;
  min-height: 240px;
  position: fixed;
}

.step {
  width: 100%;
  height: auto;
  cursor: not-allowed;
}

.cursor {
  cursor: pointer;
}

.details {
  display: flex;
}

.dotActive, .dotGreen {
  width: 24px;
  height: 24px;
  background-color: $colorPrimaryHighlight;
  border: 2px solid $colorTertiaryWhite;
  border-radius: 60px;
  position: relative;
  margin: $margin * 0.125 0;
}

.dotGreen {
  background-color: green;
}

.dotActiveInner, .dotIcon {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 2px solid $inputBorder;
  border-radius: 60px;
  top: -100%;
  left: -100%;
  transform: translate(50%, 50%);
}

.dotIcon {
  border: none;
}

.dot {
  width: 24px;
  height: 24px;
  background-color: $colorTertiaryWhite;
  border: 2px solid $inputBorder;
  border-radius: 60px;
  box-sizing: border-box;
}

.dotComplete {
  width: 24px;
  height: 24px;
  background-color: $headingColor;
  border: 5px solid $mainBg;
  border-radius: 60px;
  box-sizing: border-box;
}

.textBox {
  display: flex;
  flex-direction: column;
  margin-left: $margin * 0.5;
  position: relative;
}

.inactiveTitle, .activeTitle, .errorText {
  margin-bottom: 0;
}

.errorText {
  @include TextBodySmall12;
  position: absolute;
  bottom: -40%;
  color: $headingColor;
}

.inactiveTitle {
  @include TextBodySmall16;
  color: $headingColor;
}

.activeTitle {
  @include TextHeadingSmall18;
}

.dividerContainer {
  width: 13px;
  height: 30px;
  display: flex;
  align-items: center;
}

.divider {
  height: calc(100% - 6px);
  width: 3px;
  background-color: $inputBorder;
  margin-left: auto;
}
