@import "~styles/globals.scss";
.row {
  @include mobile {
    &:not(.dontTile) {
      background-image: none !important; // stylelint-disable-line declaration-no-important
    }

    &.dontTile {
      background-position: top 0 left 75vw !important; // stylelint-disable-line declaration-no-important
    }
  }

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
    justify-content: center;
  }

  &.right {
    text-align: right;
  }

  &.halfRow {
    background-size: 50% 100% !important; // stylelint-disable-line declaration-no-important
  }

  .fixedItemHeight {
    > div > div {
      height: 120px;
    }
  }

  &:not(.nested) {
    @include sectionStyle;
  }

  &.homeCareServices {
    padding: 0;
  }

  &.copyrightAndCredit {
    position: relative;

    .copyrightAndCredit {
      position: absolute;
      bottom: 18px;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-around;
      color: #6e768a;

      svg path {
        fill: #6e768a;
      }
    }
  }

  &.withBackground {
    @include coverBackground;
    min-height: 645px;

    @include mobile {
      min-height: auto;
    }

    &:not(.dontTile) {
      @include lt-medium {
        background-image: none !important; // stylelint-disable-line declaration-no-important
        padding-bottom: 0;

        > div {
          padding-bottom: 50px;
        }
      }
    }
  }

  &.withBackgroundFixedHeight {
    background-size: auto 100%;
    background-position: top right;

    @include mobile {
      min-height: unset !important; // stylelint-disable-line declaration-no-important
    }
  }

  &.childrenAsSlider {
    @include mobile {
      width: 100%;
      grid-template-columns: repeat(auto-fit, minmax($slideItemWidth, $slideItemWidth));
      overflow-x: auto;
      grid-auto-flow: column;
      scroll-behavior: smooth;
      display: grid;
      margin: 0 auto;
      grid-gap: 24px;

      // override column padding css
      > * {
        padding-left: 0 !important; // stylelint-disable-line declaration-no-important
        padding-right: 0 !important; // stylelint-disable-line declaration-no-important
        width: $slideItemWidth;
      }
    }
  }
}
