@import "~styles/globals.scss";
.container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 4;
}

.selectMock {
  @include TextHeadingSmall18;
  width: 100%;
  padding: 16px 32px 16px 16px;
  height: 100%;
  background-color: $inputBorder;
  position: relative;
}

.sectionTitle {
  @include TextHeadingSmall18;
}

.stepTracker {
  font-family: $font;
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  color: $blue;
}

.arrow {
  position: absolute;
  right: 30px;
  top: calc(50%);
  transform: translateY(-50%) rotate(0);
  transition: transform 0.5s;
}

.arrowToggled {
  transform: translateY(-50%) rotate(180deg);
}

.dropdown {
  position: absolute;
  background-color: $inputBorder;
  width: 100%;
  top: 100%;
  overflow: hidden;
  height: 0;
  transition: height 0.5s;
}

.dropdownToggled {
  height: 382px;
}

.innerDropdown {
  padding: 0 16px 16px;
}

.optionWrapper {
  display: flex;
  align-items: center;
  margin: $margin * 0.5 0;
}

.option {
  @include TextHeadingSmall18;
  position: relative;
  margin-bottom: 0;
  margin-left: $margin * 0.5;
}

.disabled {
  opacity: 0.5;
}

.selectedStep {
  width: 30px;
  height: 30px;
}

.dot, .dotDefault, .dotActive {
  width: 24px;
  height: 24px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  background-color: $colorTertiaryWhite;
  border: 2px solid $inputBorder;
  border-radius: 60px;
  box-sizing: border-box;
}

.dotActive {
  background-color: $colorPrimaryHighlight;
  border: 2px solid $colorTertiaryWhite;
  border-radius: 60px;
  margin: $margin * 0.125 0;
}

.dotActiveInner {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 2px solid $inputBorder;
  border-radius: 60px;
  top: -100%;
  left: -100%;
  transform: translate(50%, 50%);
}
