@import "~styles/globals.scss";
.container {
  display: flex;
  align-items: flex-start;
  margin-bottom: $margin * 1.33;

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  textarea {
    padding: 0;
    border: none;
  }

  input[type='date'] {
    color: $inputBorder;
    text-transform: uppercase;
  }
}

.stretch {
  width: 100%;
}

.label {
  @include TextBodySmall18;
  max-width: 630px;
  height: 100%;
  display: flex;
  align-items: center;

  @include mobile {
    max-width: calc(100% - 74px);
  }
}

.checkbox {
  appearance: none;
  width: 36px;
  height: 36px;
  background-color: $colorTertiaryWhite;
  border: 2px solid $inputBorder;
  outline: none;
  border-radius: 8px;
  margin-right: $margin;
  position: relative;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.11563);
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border-radius: 4px;
    top: 6px;
    left: 6px;
    transition: all 0.5s ease;
  }

  &:checked::before {
    background-color: $colorPrimaryHighlight;
  }
}
