@import "~styles/globals.scss";
.container {
  width: 330px;
  height: 77px;
  padding: 12px 18px;
  background-color: $colorTertiaryWhite;
  display: flex;
  flex-direction: column;
  border: 2px solid $inputBorder;
  border-radius: $cardRadius * 0.33;
  position: relative;
}

.label {
  @include TextHeadingSmall12;
  width: 100%;
}

.select {
  @include TextBodyMedium20;
  height: 28px;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  background-image: url(~images/icons/arrow-down.png);
  background-repeat: no-repeat;
  background-position: right 0 top 50%;
  background-size: 16px 10px;
  width: 100%;

  &::placeholder {
    @include TextBodyMedium20;
    color: $inputBorder;
    opacity: 1;
  }

  option {
    color: initial;
  }

  option:disabled {
    color: $inputBorder;
  }
}

.greyText {
  color: $inputBorder;
}
